import Table, { Column } from "shared/components/Table";
import styles from "./ErrorsPage.module.scss";
import {
    axiosFetcher,
    branchedLink,
    nFormatter,
    valueCompare,
} from "shared/utils/utils";
import PipelineIcon from "icons/pipeline.svg";
import SourceIcon from "icons/source.svg";
import { useParams, useSearchParams } from "react-router-dom";
import { ErrorLog, ErrorOrigin } from "shared/models";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import SearchBar from "shared/components/search/SearchBar";
import { Config, Filter, defaultVerb } from "shared/components/search/filters";
import {
    ErrorOriginTypeFilter,
    ErrorOriginNameFilter,
} from "shared/components/search/ErrorOriginFilter";
import { ERRORS_NAV } from "shared/constants/navigation";
import SummaryModal from "shared/components/SummaryModal";
import Convert from "ansi-to-html";
import ConsolePage from "../console-page/ConsolePage";
import useSWR from "swr";
import DateRangeControl from "shared/components/date-picker/DateRangeControl";
import { INIT_END_DATE, INIT_START_DATE } from "shared/constants/constants";
import { convertToDate, DateValue, toISOString } from "shared/components/date-picker/date-types";

const convert = new Convert();

function buildSearchConfig(sourceOrigins: ErrorOrigin[]): Config {
    const originTypes = ["Source", "Pipeline", "ExtractHistorical"];
    const originTypeConfig = {
        propertyName: "Type",
        kind: {
            kind: "multi-select" as const,
            options: originTypes.map((t) => ({ key: t, name: t, value: t })),
            valueCategory: "type",
            component: ErrorOriginTypeFilter,
            relationship: "has-one" as const,
        },
    };

    const originNamesConfig = {
        propertyName: "Origin",
        kind: {
            kind: "multi-select" as const,
            options: sourceOrigins.map((t) => ({
                key: t.origin_name,
                name: t.origin_name,
                value: t.origin_name,
                metadata: { ...t },
            })),
            valueCategory: "origin",
            component: ErrorOriginNameFilter,
            relationship: "has-one" as const,
        },
    };

    const filterOrder = ["type", "origin"];

    return {
        filterOrder,
        filterConfigs: {
            type: originTypeConfig,
            origin: originNamesConfig,
        },
    };
}

function filterErrors(
    errors: ErrorLog[],
    searchText: string,
    filters: Filter[],
    setSearchParams: (params: URLSearchParams) => void,
    force?: boolean
): ErrorLog[] {
    const text = searchText.toLocaleLowerCase();
    const filtered = errors;
    const params = new URLSearchParams();

    if (filters.length) {
        filters.forEach((f) => {
            params.set(f.key.toString(), encodeURIComponent(f.value.join(",")));
        });
        setSearchParams(params);
    } else if (force) {
        setSearchParams(params);
    }

    return filtered.filter(
        (fs) =>
            searchText === "" || fs.content.toLocaleLowerCase().includes(text)
    );
}

const formatDate = (date: Date) => {
    return date.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    });
};

function ErrorDate({ date }: { date: Date }): JSX.Element {
    return (
        <div className={styles.errorDate}>
            <div className={styles.bar} />
            {formatDate(date)}
        </div>
    );
}

function ErrorOriginType({ type }: { type: string }): JSX.Element {
    let icon = null;
    switch (type.toLocaleLowerCase()) {
        case "pipeline":
            icon = <PipelineIcon width={16} height={16} viewBox="0 0 24 24" />;
            break;
        case "source":
            icon = <SourceIcon width={16} height={16} viewBox="0 0 24 24" />;
            break;
    }

    return (
        <div className={styles.errorOriginType}>
            {icon}
            {type}
        </div>
    );
}

function ErrorContent({ content }: { content: string }): JSX.Element {
    return <div className={styles.errorContent}>{content}</div>;
}

function ErrorSummary({
    error: e,
    onClose,
}: {
    error: ErrorLog;
    onClose: () => void;
}) {
    const date = new Date(e.logged_at / 1000);
    const dateFormatted = formatDate(date);
    const formattedErrorContent = e.content
        .replace(/\\\\/g, "\\")
        .replace(/\\n/g, "<br/>")
        .replace(/\\"/g, '"');

    return (
        <SummaryModal
            header={dateFormatted}
            onClose={onClose}
            metadataCols={[
                {
                    metadataDetails: <ErrorOriginType type={e.origin_type} />,
                    metadataTitle: "Type",
                },
                {
                    metadataDetails: e.count || 1,
                    metadataTitle: "Count",
                },
                {
                    metadataDetails: e.origin_name,
                    metadataTitle: "Origin",
                },
            ]}
            content={
                <div
                    dangerouslySetInnerHTML={{
                        __html: convert.toHtml(formattedErrorContent),
                    }}
                />
            }
            contentClassName={styles.errorModalContent}
            containerClassName={styles.errorModalContainer}
        />
    );
}

function ErrorsTableLoaded({
    errors,
    setSearchParams,
    onSearch,
    defaultFilters,
    searchConfig
}: {
    errors: ErrorLog[];
    setSearchParams: (params: URLSearchParams) => void;
    onSearch: (text: string, filters: Filter[], force?: boolean) => void;
    defaultFilters: Filter[];
    searchConfig: Config;
}): JSX.Element {
    const [rowDetail, setRowDetail] = useState<ErrorLog | null>(null);

    const columns: Column<ErrorLog>[] = useMemo(() => [
        {
            header: "Date",
            renderFunc: (e) => (
                <ErrorDate date={new Date(e.logged_at / 1000)} />
            ),
            sortFunc: (x, y) => valueCompare(x.logged_at, y.logged_at),
        },
        {
            header: "Type",
            renderFunc: (e) => <ErrorOriginType type={e.origin_type} />,
            sortFunc: (x, y) => valueCompare(x.origin_type, y.origin_type),
        },
        {
            header: "Origin",
            renderFunc: (e) => e.origin_name,
            sortFunc: (x, y) => valueCompare(x.origin_name, y.origin_name),
        },
        {
            header: "Count",
            renderFunc: (e) => nFormatter(e.count || 1, 2),
            sortFunc: (x, y) => valueCompare(x.count || 1, y.count || 1),
        },
        {
            header: "Content",
            renderFunc: (e) => <ErrorContent content={e.content} />,
        },
    ], []);

    return (
        <>
            <div className={styles.table}>
                <Table
                    data={errors}
                    columns={columns}
                    rowKeyFunc={(e) => e.id}
                    dataUnit="Error"
                    onRowClick={(row) => {
                        setRowDetail(row);
                    }}
                />
            </div>
            {rowDetail && (
                <ErrorSummary
                    error={rowDetail}
                    onClose={() => setRowDetail(null)}
                />
            )}
        </>
    );
}

export default function ErrorsPage() {
    const { branchName } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const originName = searchParams.get("origin") || "";
    const originTypeString = searchParams.get("type") || "";
    const fromDateParam = searchParams.get("fromDate");
    const toDateParam = searchParams.get("toDate");

    // We keep the actual errors in local state (for local filtering, etc.).
    const [filteredErrors, setFilteredErrors] = useState<ErrorLog[]>([]);

    /**
     * 1) Convert INIT_START_DATE and INIT_END_DATE from strings 
     *    to stable numeric timestamps in state.
     */
    const [startTime, setStartTime] = useState<number>(() => convertToDate(INIT_START_DATE).getTime());
    const [endTime, setEndTime] = useState<number>(() => convertToDate(INIT_END_DATE).getTime());

    // 2) If user arrives with ?fromDate=? / ?toDate=? in URL, sync them into state once.
    useEffect(() => {
        if (!fromDateParam || !toDateParam) return;

        const fromDateMillis = Number(fromDateParam);
        const toDateMillis = Number(toDateParam);
        if (Number.isNaN(fromDateMillis) || Number.isNaN(toDateMillis)) {
            return;
        }

        // Only set if changed
        if (startTime !== fromDateMillis || endTime !== toDateMillis) {
            setStartTime(fromDateMillis);
            setEndTime(toDateMillis);
        }
    }, [fromDateParam, toDateParam, startTime, endTime]);

    // Memoize originTypes
    const originTypes = useMemo(() => {
        if (!originTypeString) return [];
        // Avoid [""] if the string is empty
        return decodeURIComponent(originTypeString).split(',').filter(Boolean);
    }, [originTypeString]);

    // Expand certain origin types, if needed
    const paramOriginTypes = useMemo(() => {
        const types = [...originTypes];
        if (originTypes.includes("Pipeline")) {
            types.push("Pipeline2");
        }
        if (originTypes.includes("Source")) {
            types.push("StreamingIngest", "BatchIngestion", "Prepare");
        }
        return types;
    }, [originTypes]);

    // 3) Build stable SWR key from numeric timestamps (in ms).
    const swrKey = useMemo(() => {
        return [
            "errors",
            branchName,
            originName,
            paramOriginTypes.join(","),
            startTime,
            endTime,
        ];
    }, [branchName, originName, paramOriginTypes, startTime, endTime]);

    // 4) Use the SWR hook to fetch data. Convert ms -> seconds for the server.
    const { data: errors = [] } = useSWR(
        swrKey,
        async ([, branch, origin, originTypesJoined, sTime, eTime]) => {
            const response = await axios.post(
                branchedLink(branch as string, "errors"),
                {
                    origin_name: origin,
                    origin_type: encodeURIComponent(originTypesJoined as string),
                    start_time: sTime ? Number(sTime) / 1000 : null,
                    end_time: eTime ? Number(eTime) / 1000 : null
                }
            );
            setFilteredErrors(response.data);
            return response.data as ErrorLog[];
        },
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            keepPreviousData: true,
        }
    );

    // 5) Fetch source origins
    const { data: sourceOrigins = [] } = useSWR(
        ["post", branchedLink(branchName, "list_error_origin")],
        axiosFetcher,
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            keepPreviousData: true,
        }
    );

    // 6) Build search config 
    const searchConfig = useMemo(() => buildSearchConfig(sourceOrigins), [sourceOrigins]);

    // 7) Build default filters from search params
    const defaultFilters: Filter[] = useMemo(() => {
        const filters: Filter[] = [];
        searchParams.forEach((value, key) => {
            if (searchConfig.filterConfigs[key]) {
                const filterValues = decodeURIComponent(value).split(',');
                filters.push({
                    key,
                    value: filterValues,
                    verb: defaultVerb(searchConfig.filterConfigs[key], filterValues),
                });
            }
        });
        return filters;
    }, [searchParams, searchConfig]);

    // 8) The local onSearch
    const onSearch = useCallback((text: string, filters: Filter[], force?: boolean) => {
        const filteredList = filterErrors(errors, text, filters, setSearchParams, force);
        setFilteredErrors(filteredList);
    }, [errors, setSearchParams]);

    return (
        <ConsolePage
            header={{
                title: ERRORS_NAV.title,
                icon: ERRORS_NAV.icon,
                actions: [
                    <DateRangeControl
                        key="date-range"
                        onDateUpdate={(fromDate, toDate) => {
                            // Convert them to numeric, store in state, update URL
                            const fromMillis = convertToDate(fromDate).getTime();
                            const toMillis = convertToDate(toDate).getTime();

                            const newSearchParams = new URLSearchParams(searchParams);
                            newSearchParams.set("fromDate", fromMillis.toString());
                            newSearchParams.set("toDate", toMillis.toString());
                            setSearchParams(newSearchParams);

                            // Update local state
                            setStartTime(fromMillis);
                            setEndTime(toMillis);
                        }}
                        // Convert numeric ms back to Date
                        initialFromDate={useMemo(() => new Date(startTime), [startTime])}
                        initialToDate={useMemo(() => new Date(endTime), [endTime])}
                        alignment="right"
                    />,
                ],
            }}
            subheader={
                <SearchBar
                    config={searchConfig}
                    onSearch={onSearch}
                    defaultFilters={defaultFilters}
                />
            }
            content={
                <ErrorsTableLoaded
                    errors={filteredErrors}
                    setSearchParams={setSearchParams}
                    onSearch={onSearch}
                    defaultFilters={defaultFilters}
                    searchConfig={searchConfig}
                />
            }
        />
    );
}
